.heading {
  text-align: center;
}

.container {
  flex-direction: row;
}

.gallery {
  display: flex;
  gap: 20px;
  margin-top: 80px;
  margin-bottom: 80px;
}

.gallery-img {
  border-radius: 10px;
  width: 315px;
  height: 210px;
  margin: auto;
}

.text-ab-h {
  font-family: "Montserrat", sans-serif;
  font-weight: 700;
  font-style: normal;
  font-size: 20px;
  background-color: black;
  color: aliceblue;
  padding: 2px 0 2px 10px;
}

.text-a {
  margin: auto;
  margin-right: 60px;
}

@media (width <= 1024px) {
  .text-a {
    margin: 0;
    margin-right: 0px;
  }
}

.text-ab-p {
  font-family: "Montserrat", sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 16px;
}

.text-ab-hh {
  margin-top: 60px;
  margin-bottom: 60px;
  font-family: "Montserrat", sans-serif;
  font-weight: 700;
  font-style: normal;
  font-size: 64px;
}

.gallery-img {
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 0.2s;
}

.orig1 {
  background-image: url("../../Photo/orig1.png");
}

.orig2 {
  background-image: url("../../Photo/orig2.png");
}
.orig3 {
  background-image: url("../../Photo/orig3.png");
}

.image-h {
  transition: 0.2s;
}

.image-h:hover {
  transform: scale(1.05);
}

.gallery-img:hover {
  transform: scale(1.03);
}

@media (width <= 365px) {
  .text-ab-hh {
    font-size: 38px;
    margin-top: 30px;
    margin-bottom: 25px;
  }

  .gallery {
    margin-bottom: 20px;
  }

  .text-ab-h {
    font-size: 16px;
  }

  .text-ab-p {
    font-size: 14px;
  }

  .text-a {
    margin-left: 20px;
    margin-right: 20px;
  }
}
