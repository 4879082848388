.main {
  padding-top: 100px;
  background-image: url("../../Photo/close-up-hairdresser-shop-instruments2.jpg");
  background-size: cover;
}

.color-text-main {
  color: #f4f4f4;
}

.main-text {
  margin: 0px;
  padding-top: 30px;
  font-size: 64px;
  font-family: "Montserrat", sans-serif;
  line-height: 55px;
  margin-bottom: 70px;
}
.p-text {
  font-family: "Montserrat", sans-serif;
  font-weight: 300;
  font-style: normal;
  font-size: 16px;
  margin-bottom: 16px;
}

.p-text-h {
  font-family: "Montserrat", sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 18px;
}

.text {
  font-size: 18px;
  justify-content: center;
  margin: 70px 0 0 0;
}

.container {
  display: flex;
  max-width: 1030px;
  margin-left: auto;
  margin-right: auto;
}

.main-text-container {
  max-width: 1030px;
  margin-left: auto;
  margin-right: auto;
}

.button-div {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
}

.image-main {
  width: 500px;
}

.alena {
  width: inherit;
  margin-bottom: -3px;
}

@media (width <= 1024px) {
  .container {
    display: flex;
    max-width: 1030px;
    margin-left: auto;
    margin-right: auto;
    flex-wrap: wrap;
    justify-content: center;
    align-content: center;
    align-items: center;
  }

  .text {
    display: flex;
    font-size: 18px;
    margin: 0px 100px 40px;
    flex-direction: column;
    align-content: center;
    align-items: center;
    justify-content: center;
  }

  .image-main {
    width: 390px;
  }

  .color-text-main {
    text-align: center;
  }
}

@media (width <= 465px) {
  .main-text {
    font-size: 48px;
    padding-top: 0px;
    margin-bottom: 20px;
  }
}


@media (width <= 365px) {
  .main-text {
    font-size: 38px;
    padding-top: 0px;
    margin-bottom: 0px;
  }

  .p-text {
    font-size: 14px;
    margin-bottom: 16px;
  }

  .image-main {
    width: 290px;
  }

  .text {
    display: flex;
    font-size: 18px;
    margin: 10px 15px 40px;
    flex-direction: column;
    align-content: center;
    align-items: center;
    justify-content: center;
  }
  
}