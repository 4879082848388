.item-servise__icon {
  border-radius: 10px;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
}

.servise__icon,
.image-gallery-image {
  width: 311px;
  height: 211px;
  border-radius: 10px;
}

.item-servise-content {
  display: flex;
  flex-direction: row-reverse;
  align-items: flex-end;
  gap: 30px;
}

@media (width <= 868px) {
  .item-servise-content {
    display: flex;
    flex-direction: row-reverse;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    gap: 30px;
  }
}

.item-servise {
  align-items: center;
  width: 85%;

  border-radius: 10px;
  padding: 20px;
  box-shadow: rgb(38, 57, 77) 0px 20px 30px -10px;

  background-color: #f2f2f2;
}
.item-menu {
  display: flex;
  justify-content: flex-start;
  gap: 20px;
  margin-bottom: 2px;
  border-bottom: rgb(27, 27, 27) 1px solid;
}

.item {
  margin-top: 20px;
  margin-bottom: 5px;
}

.text-ab-pp-item {
  width: 570px;
  font-family: "Montserrat", sans-serif;
  font-weight: 500;
  font-size: 16px;
  font-style: normal;
}

.time {
  width: 110px;
}

.price {
  width: 150px;
  text-align: right;
}

.button-div {
  justify-content: left;
  gap: 20px;
}

.container-servise {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 50px;
  max-width: 1030px;
  margin-right: auto;
  margin-bottom: 80px;
  margin-left: auto;
}

.text-servise-h {
  margin-top: 20px;
  margin-bottom: 20px;
  font-family: "Montserrat", sans-serif;
  font-weight: 500;
  font-size: 24px;
  font-style: normal;
  letter-spacing: 2px;
}

.text-ab-pp {
  font-family: "Montserrat", sans-serif;
  font-weight: 400;
  font-size: 16px;
  font-style: normal;
}

@media (width <= 365px) {
  .servise__icon,
  .image-gallery-image {
    width: 240px;
    height: 177px;
  }

  .text-servise-h {
    margin-top: 10px;
    margin-bottom: 10px;
  }

  .text-ab-pp {
    font-size: 14px;
  }

  .time,
  .price {
    font-size: 12px;
  }

  .text-ab-pp-item {
    width: 570px;

    font-size: 12px;
  }

  .button-div {
    justify-content: center;
  }
}
