.image-gallery-icon {
  position: absolute;
  z-index: 4;
  border: 0;
  outline: none;
  color: #fff;
  background-color: transparent;
  filter: drop-shadow(0 2px 2px #1a1a1a);
  transition: all .3s ease-out;
  cursor: pointer;
  appearance: none;
}

@media(hover: hover)and (pointer: fine) {
  .image-gallery-icon:hover {
    color: #337ab7;
  }

  .image-gallery-icon:hover .image-gallery-svg {
    transform: scale(1.1);
  }
}

.image-gallery-icon:focus {
  outline: 2px solid #337ab7;
}

.image-gallery-using-mouse .image-gallery-icon:focus {
  outline: none;
}

.image-gallery-fullscreen-button,
.image-gallery-play-button {
  bottom: 0;
  padding: 20px;
}

.image-gallery-fullscreen-button .image-gallery-svg,
.image-gallery-play-button .image-gallery-svg {
  width: 28px;
  height: 28px;
}

@media(max-width: 768px) {

  .image-gallery-fullscreen-button,
  .image-gallery-play-button {
    padding: 15px;
  }

  .image-gallery-fullscreen-button .image-gallery-svg,
  .image-gallery-play-button .image-gallery-svg {
    width: 24px;
    height: 24px;
  }
}

@media(max-width: 480px) {

  .image-gallery-fullscreen-button,
  .image-gallery-play-button {
    padding: 10px;
  }

  .image-gallery-fullscreen-button .image-gallery-svg,
  .image-gallery-play-button .image-gallery-svg {
    width: 16px;
    height: 16px;
  }
}

.image-gallery-fullscreen-button {
  right: 0;
}

.image-gallery-play-button {
  left: 0;
}

.image-gallery-left-nav,
.image-gallery-right-nav {
  top: 50%;
  padding: 50px 10px;
  transform: translateY(-50%);
}

.image-gallery-left-nav .image-gallery-svg,
.image-gallery-right-nav .image-gallery-svg {
  width: 20px;
  height: 120px;
}

@media(max-width: 768px) {

  .image-gallery-left-nav .image-gallery-svg,
  .image-gallery-right-nav .image-gallery-svg {
    width: 36px;
    height: 72px;
  }
}

@media(max-width: 480px) {

  .image-gallery-left-nav .image-gallery-svg,
  .image-gallery-right-nav .image-gallery-svg {
    width: 24px;
    height: 48px;
  }
}

.image-gallery-left-nav[disabled],
.image-gallery-right-nav[disabled] {
  opacity: .6;
  cursor: disabled;
  pointer-events: none;
}

.image-gallery-left-nav {
  left: 0;
}

.image-gallery-right-nav {
  right: 0;
}

.image-gallery {
  position: relative;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
  user-select: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

.image-gallery.fullscreen-modal {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 5;
  width: 100%;
  height: 100%;
  background: #000;
}

.image-gallery.fullscreen-modal .image-gallery-content {
  top: 50%;
  transform: translateY(-50%);
}

.image-gallery-content {
  position: relative;
  top: 0;
  line-height: 0;
}

.image-gallery-content.fullscreen {
  background: #000;
}

.image-gallery-content .image-gallery-slide .image-gallery-image {
  max-height: calc(100vh - 80px);
}

.image-gallery-content.image-gallery-thumbnails-left .image-gallery-slide .image-gallery-image,
.image-gallery-content.image-gallery-thumbnails-right .image-gallery-slide .image-gallery-image {
  max-height: 100vh;
}

.image-gallery-slide-wrapper {
  position: relative;
}

.image-gallery-slide-wrapper.image-gallery-thumbnails-left,
.image-gallery-slide-wrapper.image-gallery-thumbnails-right {
  display: inline-block;
  width: calc(100% - 110px);
}

@media(max-width: 768px) {

  .image-gallery-slide-wrapper.image-gallery-thumbnails-left,
  .image-gallery-slide-wrapper.image-gallery-thumbnails-right {
    width: calc(100% - 87px);
  }
}

.image-gallery-slide-wrapper.image-gallery-rtl {
  direction: rtl;
}

.image-gallery-slides {
  position: relative;
  overflow: hidden;
  line-height: 0;
  text-align: center;
  white-space: nowrap;
}

.image-gallery-slide {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
}

.image-gallery-slide.image-gallery-center {
  position: relative;
}

/* .image-gallery-slide .image-gallery-image {
  width: 100%;
  object-fit: contain;
} */

.image-gallery-slide .image-gallery-description {
  position: absolute;
  bottom: 70px;
  left: 0;
  padding: 10px 20px;
  color: #fff;
  line-height: 1;
  background: rgba(0, 0, 0, .4);
  white-space: normal;
}

@media(max-width: 768px) {
  .image-gallery-slide .image-gallery-description {
    bottom: 45px;
    padding: 8px 15px;
    font-size: .8em;
  }
}

.image-gallery-bullets {
  position: absolute;
  right: 0;
  bottom: 20px;
  left: 0;
  z-index: 4;
  width: 80%;
  margin: 0 auto;
}

.image-gallery-bullets .image-gallery-bullets-container {
  margin: 0;
  padding: 0;
  text-align: center;
}

.image-gallery-bullets .image-gallery-bullet {
  display: inline-block;
  margin: 0 5px;
  border: 1px solid #fff;
  border-radius: 50%;
  padding: 5px;
  box-shadow: 0 2px 2px #1a1a1a;
  outline: none;
  background-color: transparent;
  transition: all .2s ease-out;
  cursor: pointer;
  appearance: none;
}

@media(max-width: 768px) {
  .image-gallery-bullets .image-gallery-bullet {
    margin: 0 3px;
    padding: 3px;
  }
}

@media(max-width: 480px) {
  .image-gallery-bullets .image-gallery-bullet {
    padding: 2.7px;
  }
}

.image-gallery-bullets .image-gallery-bullet:focus {
  border: 1px solid #337ab7;
  background: #337ab7;
  transform: scale(1.2);
}

.image-gallery-bullets .image-gallery-bullet.active {
  border: 1px solid #fff;
  background: #fff;
  transform: scale(1.2);
}

@media(hover: hover)and (pointer: fine) {
  .image-gallery-bullets .image-gallery-bullet:hover {
    border: 1px solid #337ab7;
    background: #337ab7;
  }

  .image-gallery-bullets .image-gallery-bullet.active:hover {
    background: #337ab7;
  }
}

.image-gallery-thumbnails-wrapper {
  position: relative;
}

.image-gallery-thumbnails-wrapper.thumbnails-swipe-horizontal {
  touch-action: pan-y;
}

.image-gallery-thumbnails-wrapper.thumbnails-swipe-vertical {
  touch-action: pan-x;
}

.image-gallery-thumbnails-wrapper.thumbnails-wrapper-rtl {
  direction: rtl;
}

.image-gallery-thumbnails-wrapper.image-gallery-thumbnails-left,
.image-gallery-thumbnails-wrapper.image-gallery-thumbnails-right {
  display: inline-block;
  width: 100px;
  vertical-align: top;
}

@media(max-width: 768px) {

  .image-gallery-thumbnails-wrapper.image-gallery-thumbnails-left,
  .image-gallery-thumbnails-wrapper.image-gallery-thumbnails-right {
    width: 81px;
  }
}

.image-gallery-thumbnails-wrapper.image-gallery-thumbnails-left .image-gallery-thumbnails,
.image-gallery-thumbnails-wrapper.image-gallery-thumbnails-right .image-gallery-thumbnails {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding: 0;
}

.image-gallery-thumbnails-wrapper.image-gallery-thumbnails-left .image-gallery-thumbnails .image-gallery-thumbnail,
.image-gallery-thumbnails-wrapper.image-gallery-thumbnails-right .image-gallery-thumbnails .image-gallery-thumbnail {
  display: block;
  margin-right: 0;
  padding: 0;
}

.image-gallery-thumbnails-wrapper.image-gallery-thumbnails-left .image-gallery-thumbnails .image-gallery-thumbnail+.image-gallery-thumbnail,
.image-gallery-thumbnails-wrapper.image-gallery-thumbnails-right .image-gallery-thumbnails .image-gallery-thumbnail+.image-gallery-thumbnail {
  margin-top: 2px;
  margin-left: 0;
}

.image-gallery-thumbnails-wrapper.image-gallery-thumbnails-left,
.image-gallery-thumbnails-wrapper.image-gallery-thumbnails-right {
  margin: 0 5px;
}

@media(max-width: 768px) {

  .image-gallery-thumbnails-wrapper.image-gallery-thumbnails-left,
  .image-gallery-thumbnails-wrapper.image-gallery-thumbnails-right {
    margin: 0 3px;
  }
}

.image-gallery-thumbnails {
  padding: 5px 0;
  overflow: hidden;
}

@media(max-width: 768px) {
  .image-gallery-thumbnails {
    padding: 3px 0;
  }
}

.image-gallery-thumbnails .image-gallery-thumbnails-container {
  text-align: center;
  cursor: pointer;
  white-space: nowrap;
}

.image-gallery-thumbnail {
  display: inline-block;
  width: 100px;
  border: 4px solid transparent;
  padding: 0;
  background: transparent;
  transition: border .3s ease-out;
}

@media(max-width: 768px) {
  .image-gallery-thumbnail {
    width: 81px;
    border: 3px solid transparent;
  }
}

.image-gallery-thumbnail+.image-gallery-thumbnail {
  margin-left: 2px;
}

.image-gallery-thumbnail .image-gallery-thumbnail-inner {
  position: relative;
  display: block;
}

.image-gallery-thumbnail .image-gallery-thumbnail-image {
  width: 100%;
  vertical-align: middle;
  line-height: 0;
}

.image-gallery-thumbnail.active,
.image-gallery-thumbnail:focus {
  border: 4px solid #337ab7;
  outline: none;
}

@media(max-width: 768px) {

  .image-gallery-thumbnail.active,
  .image-gallery-thumbnail:focus {
    border: 3px solid #337ab7;
  }
}

@media(hover: hover)and (pointer: fine) {
  .image-gallery-thumbnail:hover {
    border: 4px solid #337ab7;
    outline: none;
  }
}

@media(hover: hover)and (pointer: fine)and (max-width: 768px) {
  .image-gallery-thumbnail:hover {
    border: 3px solid #337ab7;
  }
}

.image-gallery-thumbnail-label {
  position: absolute;
  top: 50%;
  left: 0;
  box-sizing: border-box;
  width: 100%;
  padding: 5%;
  color: #fff;
  font-size: 1em;
  line-height: 1em;
  text-shadow: 0 2px 2px #1a1a1a;
  transform: translateY(-50%);
  white-space: normal;
}

@media(max-width: 768px) {
  .image-gallery-thumbnail-label {
    font-size: .8em;
    line-height: .8em;
  }
}

.image-gallery-index {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 4;
  padding: 10px 20px;
  color: #fff;
  line-height: 1;
  background: rgba(0, 0, 0, .4);
}

@media(max-width: 768px) {
  .image-gallery-index {
    padding: 5px 10px;
    font-size: .8em;
  }
}