/* CSS */
.button {
  appearance: none;
  background-color: rgb(246, 139, 0);
  /* border: 2px solid #1A1A1A; */
  border: none;
  border-radius: 15px;
  box-sizing: border-box;
  color: #FFFFFF;
  cursor: pointer;
  display: inline-block;
  font-family: "Montserrat", sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 20px;
  letter-spacing: 3px;
  line-height: normal;
  margin: 0;
  min-height: 60px;
  min-width: 0;
  outline: none;
  padding: 16px 24px;
  text-align: center;
  text-decoration: none;
  transition: all 300ms cubic-bezier(.23, 1, 0.32, 1);
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  width: 300px;
  height: 45px;
  will-change: transform;
}

.button:disabled {
  pointer-events: none;
}

.button:hover {
  box-shadow: rgba(0, 0, 0, 0.25) 0 8px 15px;
  transform: translateY(-2px);
}

.button:active {
  box-shadow: none;
  transform: translateY(0);
}



@media (width <= 365px) {
  .button {
    border-radius: 12px;
    font-size: 16px;
    min-height: 20px;
    width: 215px;
    height: 50px;
  }
  
}


/* CSS */
.button-28 {
  appearance: none;
    background-color: transparent;
    border: 2px solid #1A1A1A;
    border-radius: 10px;
    box-sizing: border-box;
    color: #3B3B3B;
    cursor: pointer;
    display: inline-block;
     font-family: "Montserrat", sans-serif;
      font-weight: 500;
      font-style: normal;
      font-size: 18px;
    line-height: normal;
    margin: 0;
    /* min-height: 60px; */
    min-width: 0;
    outline: none;
    /* padding: 16px 24px; */
    text-align: center;
    text-decoration: none;
    transition: all 300ms cubic-bezier(.23, 1, 0.32, 1);
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    width: 200px;
    height: 40px;
    will-change: transform
}

.button-28:disabled {
  pointer-events: none;
}

.button-28:hover {
  color: #fff;
  background-color: #1A1A1A;
  box-shadow: rgba(0, 0, 0, 0.25) 0 8px 15px;
  transform: translateY(-2px);
}

.button-28:active {
  box-shadow: none;
  transform: translateY(0);
}