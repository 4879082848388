.footer {
  height: 115px;
  display: flex;
  justify-content: center;
  background: #e4ebec;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px,
    rgba(0, 0, 0, 0.3) 0px 30px 60px -30px,
    rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset;
}

.footer-content {
  width: 1130px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.footer-adrress {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  gap: 50px;
}

.svg-container {
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: flex-start;
}

.footer-adrress-block {
  color: #352454;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  gap: 10px;
}

.small-text {
  text-decoration: none;
  color: #352454;
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 14px;
}

.svg-container-div {
  color: #352454;
}

.name-adrress {
  font-family: "Roboto", sans-serif;
  font-weight: 500;
  font-style: normal;
  font-size: 16px;
}

address:hover {
  border-bottom: rgb(246, 139, 0) 1px solid;
  padding-bottom: 3px;
}

.svg-social {
  transition: 0.2s;
  margin-right: 10px;
}

.svg-social:hover {
  transform: scale(1.1);
}

.svg-container-div {
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  justify-content: center;
  align-content: center;
  text-decoration: none;
  font-family: "Roboto", sans-serif;
  font-weight: 500;
  font-style: normal;
  font-size: 14px;
}

.svg-container-div:hover {
  border-bottom: rgb(246, 139, 0) 1px solid;
  padding-bottom: 3px;
}

@media (width <= 465px) {
  .footer-content {
    flex-direction: column;
    align-content: center;
    align-items: center;
    gap: 5px;
    margin-top: 6px;
    margin-bottom: 6px;
  }

  .name-adrress {
    font-size: 14px;
  }

  .small-text,
  .svg-container-div {
    font-size: 12px;
  }
}
