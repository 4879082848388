/* 
  .faq-title {
  }
 
  .faq-body {
  .faq-row {
  .row-title {
  }
 
  .row-content {
  .row-content-text {
  }
  }
  }
  }
 } */

.container-faq {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 80px;
}

.faq-row {
  display: flex;
  border-radius: 10px;
  background-color: #e4ebec;
  border-bottom: 50px solid #858585;
  box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;
}

.faq-body {
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 70%;
}

.row-title {
  margin-left: 30px;
  font-weight: 500;
}

.row-title-text {
  font-family: "Montserrat", sans-serif;
  font-weight: 500;
  font-style: normal;
  font-size: 18px;
}

.row-content {
  background-color: #fafafa;
  border-radius: 0 0 10px 10px;
  margin-bottom: -6px;
  border-top: 1px solid #858585;
}

.row-content-text {
  margin-top: 8px;
  margin-left: -20px;
  margin-right: 30px;
  font-family: "Montserrat", sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 18px;
}

.icon-wrapper {
  width: 20px;
  height: 20px;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 28px;
}

.faq-row-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
}

@media (width <= 1024px) {
  .faq-body {
    width: 93%;
  }
}
