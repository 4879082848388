.li {
  list-style: none;
}

.a {
  color: aliceblue;
  text-decoration: none;
  font-family: "Montserrat", sans-serif;
  font-optical-sizing: auto;
  font-style: normal;
  font-weight: 400;
  padding: 5px;
}

.a:hover {
  border-bottom: rgb(246, 139, 0) 1px solid;
}

.menu-ul {
  display: flex;
  flex-direction: row;
  gap: 20px;
  margin: 0px;
  padding: 0px;
}

@media (width <= 1024px) {
  .a {
    font-size: 14px;
  }
}

@media (width <= 465px) {
  .a {
    font-size: 12px;
  }
}

@media (width <= 365px) {
  .menu-ul {
    gap: 5px;
  }

  .a {
    font-size: 14px;
    font-weight: 400;
  }
}
