

.href-s {
  text-decoration: none;
  color: black;
  background-color: transparent;
}

.info-me {
  background-color: #e4ebec;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.info--me {
  margin: 2px;
  font-size: 14px;
}