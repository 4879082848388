.header-header {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  width: 1030px;
}

.Header {
  display: flex;
  justify-content: center;
  height: 100px;
  background: transparent;
  position: absolute;
  width: 95%;
}

.logo {
  color: aliceblue;
  font-family: "Montserrat", sans-serif;
  font-weight: 700;
  font-style: normal;
}

@media (width <= 1024px) {
  .header-header {
    display: flex;
    flex-direction: column;
    width: 1030px;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    gap: 20px;
    margin-left: 5px;
    margin-top: 20px;
  }

  .Header {
    display: flex;
    justify-content: center;
    height: auto;
    background: transparent;
    position: absolute;
    width: 95%;
    margin-left: 5px;
  }
}

@media (width <= 465px) {
  .logo {
    font-size: 26px;
  }

  .header-header {
    gap: 10px;
  }
}
